// import auth from 'app/auth/store/reducers';
import { combineReducers } from 'redux';
import fuse from './fuse';
import site from './site.reducer';
import market from './market.reducer';
import app from './app.reducer';
import profile from './profile.reducer';
import licenseGroups from './licenseGroups.reducer';
import users from './users.reducer';
import devices from './devices.reducer';
import deviceGroups from './deviceGroups.reducer';
import policies from './policies.reducer';
import logs from './logs.reducer';
import alertSubscriptions from './alertSubscriptions.reducer';
import mpDevices from './mpDevices.reducer';
import report from './report.reducer';
import vault from './vault.reducer';

const reducer = combineReducers({
	// auth,
	fuse,
	site,
	market,
	app,
	profile,
	licenseGroups,
	users,
	devices,
	deviceGroups,
	policies,
	logs,
	alertSubscriptions,
	mpDevices,
	report,
	vault
});

export default reducer;

// Selectors
export * from './site.reducer';
export * from './market.reducer';
export * from './app.reducer';
export * from './profile.reducer';
export * from './licenseGroups.reducer';
export * from './users.reducer';
export * from './devices.reducer';
export * from './deviceGroups.reducer';
export * from './policies.reducer';
export * from './logs.reducer';
export * from './alertSubscriptions.reducer';
export * from './mpDevices.reducer';
export * from './report.reducer';
export * from './vault.reducer';
