import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';

type Props = ButtonProps & {
	loading?: boolean;
	done?: boolean;
};

const LoadingButton = ({ loading, done, ...props }: Props) => {
	if (done) {
		return (
			<Button {...props}>
				<CheckIcon />
			</Button>
		);
	}
	if (loading) {
		return (
			<Button {...props} disabled>
				<CircularProgress size={24} />
			</Button>
		);
	}
	return <Button {...props} />;
};

export default LoadingButton;
