import React from 'react';
import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import WelcomePageConfig from 'app/main/welcome/WelcomePageConfig';
import PricingPageConfig from 'app/main/pricing/PricingPageConfig';
import MaintenancePageConfig from 'app/main/maintenance/MaintenancePageConfig';
import ComingSoonPageConfig from 'app/main/coming-soon/ComingSoonPageConfig';
import ConfirmationPageConfig from 'app/main/confirmation/ConfirmationPageConfig';
import InvitePageConfig from 'app/main/invite/InvitePageConfig';
import ReceiptPageConfig from 'app/main/receipt/ReceiptPageConfig';
import Error500PageConfig from 'app/main/error500/Error500PageConfig';
import LegalPageConfig from 'app/main/legal/LegalPageConfig';
import ProfilePageConfig from 'app/main/profile/ProfilePageConfig';
import DashboardPageConfig from 'app/main/dashboard/DashboardPageConfig';
import UsersPageConfig from 'app/main/users/UsersPageConfig';
import DevicesPageConfig from 'app/main/devices/DevicesPageConfig';
import PoliciesPageConfig from 'app/main/policies/PoliciesPageConfig';
import LogsPageConfig from 'app/main/logs/LogsPageConfig';
import SettingsPageConfig from 'app/main/settings/SettingsPageConfig';
import SubscriptionDetailsPageConfig from 'app/main/subscription-details/SubscriptionDetailsPageConfig';
import TermLicensesPageConfig from 'app/main/term-licenses/TermLicensesPageConfig';
// import LoginPageConfig from 'app/main/login/LoginPageConfig';
import ReportsPageConfig from 'app/main/reports/ReportsPageConfig';

const routeConfigs = [
	// Store
	WelcomePageConfig,
	PricingPageConfig,
	MaintenancePageConfig,
	ComingSoonPageConfig,
	ConfirmationPageConfig,
	InvitePageConfig,
	ReceiptPageConfig,
	Error500PageConfig,
	LegalPageConfig,
	ProfilePageConfig,
	// LoginPageConfig,
	// Admin
	DashboardPageConfig,
	UsersPageConfig,
	DevicesPageConfig,
	PoliciesPageConfig,
	LogsPageConfig,
	SettingsPageConfig,
	SubscriptionDetailsPageConfig,
	TermLicensesPageConfig,
	ReportsPageConfig,
];

const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: '/admin',
		exact: true,
		component: () => <Redirect to="/admin/dashboard" />
	},
	{
		component: React.lazy(() => import('app/main/error404/Error404Page'))
	}
];

export default routes;
