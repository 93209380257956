import { PolicyItem } from 'app/store/types';

const allPolicyItems: Omit<PolicyItem, 'requirement' | 'remediation'>[] = [
	{
		options: [{ val: true, tag: 'req' }],
		key: 'adminpw'
	},
	{
		options: [{ val: true, tag: 'req' }],
		key: 'adminpwr'
	},
	{
		options: [
			{ val: 5, tag: 'timed' },
			{ val: 15, tag: 'timed' },
			{ val: 30, tag: 'timed' },
			{ val: 45, tag: 'timed' },
			{ val: 60, tag: 'timed' },
			{ val: 120, tag: 'timed' },
			{ val: 240, tag: 'timed' },
			{ val: 480, tag: 'timed' },
			{ val: 'Day1', tag: 'timed' },
			{ val: 'Day2', tag: 'timed' },
			{ val: 'Day3', tag: 'timed' },
			{ val: 'Day7', tag: 'timed' },
			{ val: 'Day30', tag: 'timed' }
		],
		key: 'autodoc'
	},
	// CHANGEME::figure out what this setting is doing...
	// {
	// 	options: [{ val: true, tag: 'req' }],
	// 	key: 'backup'
	// },
	{
		options: [
			{ val: 5, tag: 'timed' },
			{ val: 15, tag: 'timed' },
			{ val: 30, tag: 'timed' },
			{ val: 45, tag: 'timed' },
			{ val: 60, tag: 'timed' },
			{ val: 120, tag: 'timed' },
			{ val: 240, tag: 'timed' },
			{ val: 480, tag: 'timed' },
			{ val: 'Day1', tag: 'timed' },
			{ val: 'Day2', tag: 'timed' },
			{ val: 'Day3', tag: 'timed' },
			{ val: 'Day7', tag: 'timed' },
			{ val: 'Day30', tag: 'timed' }
		],
		key: 'encryptpdf'
	},
	{
		options: [
			{ val: 5, tag: 'timed' },
			{ val: 15, tag: 'timed' },
			{ val: 30, tag: 'timed' },
			{ val: 45, tag: 'timed' },
			{ val: 60, tag: 'timed' },
			{ val: 120, tag: 'timed' },
			{ val: 240, tag: 'timed' },
			{ val: 480, tag: 'timed' },
			{ val: 'Day1', tag: 'timed' },
			{ val: 'Day2', tag: 'timed' },
			{ val: 'Day3', tag: 'timed' },
			{ val: 'Day7', tag: 'timed' },
			{ val: 'Day30', tag: 'timed' }
		],
		key: 'idandprint'
	},
	{
		options: [
			{ val: 5, tag: 'timed' },
			{ val: 15, tag: 'timed' },
			{ val: 30, tag: 'timed' },
			{ val: 45, tag: 'timed' },
			{ val: 60, tag: 'timed' },
			{ val: 120, tag: 'timed' },
			{ val: 240, tag: 'timed' },
			{ val: 480, tag: 'timed' },
			{ val: 'Day1', tag: 'timed' },
			{ val: 'Day2', tag: 'timed' },
			{ val: 'Day3', tag: 'timed' },
			{ val: 'Day7', tag: 'timed' },
			{ val: 'Day30', tag: 'timed' }
		],
		key: 'securebox'
	},
	{
		options: [{ val: true, tag: 'req' }],
		key: 'tempdata'
	},
	{
		options: [{ val: true, tag: 'req' }],
		key: 'hddencrypt'
	},
	{
		options: [{ val: true, tag: 'req' }],
		key: 'hddpass'
	},
	{
		options: [{ val: true, tag: 'req' }],
		key: 'userauth'
	},
	{
		options: [
			{ val: 1, tag: 'off' },
			{ val: 2, tag: 'onwi' },
			{ val: 3, tag: 'onwo' }
		],
		key: 'publicauth'
	},
	{
		options: [
			{ val: 'Admin', tag: 'crtenab-admin-mode' },
			{ val: 'UserAdmin', tag: 'crtenab-admin-mode-and-user-mode' }
		],
		key: 'crtenab'
	},
	{
		options: [
			{ val: true, tag: 'req' },
			{ val: false, tag: 'noreq' }
		],
		key: 'ssl'
	},
	{
		options: [
			{ val: true, tag: 'req' },
			{ val: false, tag: 'noreq' }
		],
		key: 'tls10'
	},
	{
		options: [
			{ val: true, tag: 'req' },
			{ val: false, tag: 'noreq' }
		],
		key: 'tls11'
	},
	{
		options: [
			{ val: true, tag: 'req' },
			{ val: false, tag: 'noreq' }
		],
		key: 'tls12'
	},
	{
		options: [
			{ val: true, tag: 'req' },
			{ val: false, tag: 'noreq' }
		],
		key: 'tls13'
	},
	{
		options: [
			{ val: 1, tag: 'timed' },
			{ val: 2, tag: 'timed' },
			{ val: 3, tag: 'timed' },
			{ val: 4, tag: 'timed' },
			{ val: 5, tag: 'timed' },
			{ val: 6, tag: 'timed' },
			{ val: 7, tag: 'timed' },
			{ val: 8, tag: 'timed' },
			{ val: 9, tag: 'timed' },
			{ val: 10, tag: 'timed' },
			{ val: 20, tag: 'timed' },
			{ val: 30, tag: 'timed' },
			{ val: 40, tag: 'timed' },
			{ val: 50, tag: 'timed' },
			{ val: 60, tag: 'timed' }
		],
		key: 'admtmout'
	},
	{
		options: [
			{ val: 1, tag: 'timed' },
			{ val: 2, tag: 'timed' },
			{ val: 3, tag: 'timed' },
			{ val: 4, tag: 'timed' },
			{ val: 5, tag: 'timed' },
			{ val: 6, tag: 'timed' },
			{ val: 7, tag: 'timed' },
			{ val: 8, tag: 'timed' },
			{ val: 9, tag: 'timed' },
			{ val: 10, tag: 'timed' },
			{ val: 20, tag: 'timed' },
			{ val: 30, tag: 'timed' },
			{ val: 40, tag: 'timed' },
			{ val: 50, tag: 'timed' },
			{ val: 60, tag: 'timed' }
		],
		key: 'usrtmout'
	},
	{
		options: [
			{ val: true, tag: 'req' },
			{ val: false, tag: 'noreq' }
		],
		key: 'ftsvenab'
	},
	{
		options: [
			{ val: true, tag: 'req' },
			{ val: false, tag: 'noreq' }
		],
		key: 'fttxenab'
	},
	{
		options: [{ val: true, tag: 'req' }],
		key: 'jblgenab'
	},
	{
		options: [
			{ val: true, tag: 'req' },
			{ val: false, tag: 'noreq' }
		],
		key: 'snmpv1v2'
	},
	{
		options: [
			{ val: true, tag: 'req' },
			{ val: false, tag: 'noreq' }
		],
		key: 'snmpv3'
	},
	{
		options: [
			{ val: true, tag: 'req' },
			{ val: false, tag: 'noreq' }
		],
		key: 'slp'
	},
	{
		options: [{ val: true, tag: 'req' }],
		key: 'ultilicensed'
	},
	{
		options: [{ val: true, tag: 'req' }],
		key: 'patternfileversion'
	},
	{
		options: [
			{ val: true, tag: 'req' },
			{ val: false, tag: 'noreq' }
		],
		key: 'rtscan'
	},
	{
		options: [
			{ val: true, tag: 'req' },
			{ val: false, tag: 'noreq' }
		],
		key: 'regscan'
	}
];

export default allPolicyItems;

export const remediatablePolicyItems: PolicyItem['key'][] = [
	'crtenab',
	'ssl',
	'tls10',
	'tls11',
	'tls12',
	'tls13',
	'admtmout',
	'usrtmout',
	'ftsvenab',
	'fttxenab',
	'jblgenab',
	'autodoc',
	'encryptpdf',
	'idandprint',
	'securebox',
	'snmpv1v2',
	'snmpv3',
	'slp',
	'rtscan',
	'regscan'
];
